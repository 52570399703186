import React from "react";
import "./report.css";
import Nav from "../navBar/navBar.jsx";

function report() {
  return (
    <div>
      <div className="NavBar">
        <Nav />
      </div>
    </div>
  );
}

export default report;
