import React from 'react'
import'./perfil.css'; 
import Nav from '../navBar/navBar.jsx';



function perfil() {
  return (
    <div>
    <div className='NavBar'>
    <Nav/>
    </div>

    </div>
  )
}

export default perfil