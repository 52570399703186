import React from "react";
import "./settings.css";
import Nav from "../navBar/navBar.jsx";

function settings() {
  return (
    <div>
      <div className="NavBar">
        <Nav />
      </div>
    </div>
  );
}

export default settings;
