import React from 'react'
import './config_usr.css'
import Nav from '../navBar/navBar.jsx';

function config_usr() {
  return (
    <div>
    <div className='NavBar'>
    <Nav/>
    </div>
  
  
  
  
  </div>
  )
}

export default config_usr