import React from 'react'
import {BsCart3, BsGrid1X2Fill,BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsListCheck, BsMenuButtonWideFill, BsFillGearFill} from 'react-icons/bs'
import './sidebar.css';
import "bootstrap-icons/font/bootstrap-icons.css";




function Sidebar() {
  return (
    <aside id="sidebar" className='position-absolute top-0 start-0'>
      <div className='Sidebar-title'> 
      <div className='sidebar-brand'>
        <BsCart3  className='icon_header'/> SHOP
      </div>
      <span className='icon close_icon'>x</span>
     </div>

      <ul className='sidebar-list'>
        <li className=' sidebar-list-item'>
          <a href=' ' >
            <BsGrid1X2Fill className='icon' />Dashboard
          </a>
        </li>
        <li className=' sidebar-list-item'>
          <a href=' ' >
            <BsFillArchiveFill className='icon' />Products
          </a>
        </li>
        <li className=' sidebar-list-item'>
          <a href=' ' >
            <BsFillGrid3X3GapFill className='icon' />Categories
          </a>
        </li>
        <li className=' sidebar-list-item'>
          <a href=' ' >
            <BsPeopleFill className='icon' />Customers
          </a>
        </li>
        <li className=' sidebar-list-item'>
          <a href=' ' >
            <BsListCheck className='icon' />Inventory
          </a>
        </li>
        <li className=' sidebar-list-item'>
          <a href=' ' >
            <BsMenuButtonWideFill className='icon' />Reports
          </a>
        </li>
        <li className=' sidebar-list-item'>
          <a href=' ' >
            <BsFillGearFill className='icon' />Settings
          </a>
        </li>
      </ul>
    </aside>
  )
}

export default Sidebar
