import React from "react";
import "./navBar.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsFillGearFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link } from 'react-router-dom';

//importes para los links del NavBar
import Dashboard from '../dashboard/dashboardApp.jsx';
import Orders from '../orders/orders.jsx';
import Customers from '../customers/customers.jsx';
import Report from '../reeport/report.jsx'
import Settingss from '../settings/settings.jsx'
import Perfil from '../perfil/perfil.jsx';
import Config_usr from '../configuraciones_usr/config_usr.jsx';
import logout from "../logout/logout.jsx";

function navBar() {
  return (
    <div className="sidebar d-flex flex-column justify-content-between bg-dark text-white p-4 vh-100 position-absolute top-0 start-0">
      <div>
        <Link to="/NavBar" className="d-flex align-items-center">
          <i className="bi bi-receipt-cutoff fs-5 me-3 text-white"></i>
          <span className="fs-4 text-white">Inventory </span>
        </Link>
        <hr className="text-secondary mt-2" />
        <ul className="nav nav-pills flex-column px-0 ">
          <li className="nav-item py-1">
            <Link to="/Dashboard" className="nav-link text white px-2">
              <i className="bi bi-speedometer me-2 fs-5 text-white"></i>
              <span className="fs-5 text-light ">Dashboard</span>
            </Link>
          </li>
          <li className="nav-item ">
            <a href="/Orders" className="nav-link text white custom-left">
              <i className=" text-light bi bi-table me-2 fs-5"></i>
              <span className="fs-5 text-light">Orders</span>
            </a>
          </li>
          <li className="nav-item">
            <a href= '/Customers' className="nav-link text white custom-left">
              <i className=" text-light bi bi-people me-2 fs-5"></i>
              <span className="fs-5 text-light">Customers</span>
            </a>
          </li>
          <li className=" nav-item">
            <a href="/Report" className="nav-link text white custom-left">
              <i className=" text-light bi bi-grid me-2 fs-5"></i>
              <span className="fs-5 text-light ">Report</span>
            </a>
          </li>
          <li className=" nav-item">
            <a href="/Settings " className="nav-link text white custom-left">
              <span className="fs-5 text-light ">
                <BsFillGearFill className="icon text-light bi me-2 fs-5" />
                Settings
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div className="text-secondary custom-left">
        <div className="User-feel sidebar">
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img (31).webp"
                class="rounded-circle"
                height="22"
                alt="Portrait of a Woman"
                loading="lazy"
              />
            </button>
            <ul
              class="dropdown-menu   p-3 mb-2 bg-dark text-white custom-left  "
              aria-labelledby="dropdownMenuButton "
            >
              <li>
                <a class="dropdown-item p-3 mb-2 bg-dark text-white" href="Perfil">
                  Mi Perfil
                </a>
              </li>
              <li>
                <a class="dropdown-item p-3 mb-2 bg-dark text-white" href="Config_usr">
                  Configuraciones
                </a>
              </li>
              <li className="nav-item">
                <a class="dropdown-item p-3 mb-2 bg-dark text-white" href="Logout">
                  Desconectarse
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default navBar;
