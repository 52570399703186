import axios from 'axios';

let AXIOURL = '';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        AXIOURL = 'http://'+(process.env.REACT_APP_INVENTORY_IP)+':'+(process.env.REACT_APP_INVENTORY_PORT)
    } else {
        AXIOURL = 'https://'+(process.env.REACT_APP_INVENTORY_IP)+':'+(process.env.REACT_APP_INVENTORY_PORT)
    }

export default axios.create({
    baseURL: AXIOURL
});
