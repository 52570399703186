import React from 'react'
import './customers.css'
import Nav from '../navBar/navBar.jsx';


function customers() {
  return (
    <div>
      <div className='NavBar'>
        <Nav/>
      </div>
      
    </div>
  )
}

export default customers
