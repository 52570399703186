import { useRef, useState, useEffect } from "react";
import './login.css';
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import axios from '../axios/axios.jsx';
import NavBar from '../navBar/navBar.jsx';

const LOGIN_URL = '/login';
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Login = () => {
  const userRef = useRef();
  const errRef = useRef(null);

  const [user, setUser] = useState('');
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user])

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === pwd);
  }, [pwd])

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      return;
    }
    console.log("user %s and password %s", user, pwd)
    try {
      console.log("Trying ...")
      const response = await axios.post(LOGIN_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: false
        });
      console.log(response?.data);
      console.log(response?.accessToken);
      //console.log(JSON.stringify(response))
      setSuccess(true);
      //clear state and controlled inputs
      //need value attrib on inputs for this
      setUser('');
      setPwd('');
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No existe respuesta desde el Servidor.');
      } else if (err.response?.status === 409) {
        setErrMsg('Usuario Registrado.');
      } else {
        setErrMsg('Registro Fallido.')
      }
      if (errRef.current) {
        errRef.current.focus();
      } else {
        console.warn('errRef is undefined');
      }
    }
  }

  return (
    <>
      {success ? (
        <section>
          <NavBar />
        </section>
      ) : (
        <section>
          <div>
            <div className='wrapper'>
              <form onSubmit={handleSubmit}>
                <h1>Login</h1>
                <div className="input-box">
                  <input
                    type="text"
                    placeholder="Usuario"
                    className="form-control"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    aria-invalid={validName ? "false" : "true"}
                    required
                    onFocus={() => setUserFocus(true)}
                    onBlur={() => setUserFocus(false)}
                  />
                  <FaUser className='icon' />
                </div>
                <div className="input-box">
                  <input
                    type="password"
                    placeholder="Contraseña"
                    className="form-control"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote"
                    required
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                  />
                  <FaLock className='icon' />
                </div>
                <div className="remember-forgot">
                  <label><input type="checkbox" />Recuerdame </label>
                  <br></br>
                  <a href="#">Olvidaste tu contraseña?</a>
                </div>
                <button type='submit'>Login </button>
                <div className='register-link'><p>No Tienes una Cuenta ? <a href="/register">Registrate</a></p></div>

              </form>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
export default Login