import React from 'react'
import './orders.css';
import Nav from '../navBar/navBar.jsx'

function orders() {
  return (
    <div>
      <div className="NavBar">
        <Nav />
      </div>
    </div>
  )
}

export default orders
