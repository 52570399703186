import { useRef, useState, useEffect } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from '../axios/axios.jsx';
import './register.css';
import '../login/login.css';
import Button from 'react-bootstrap/Button';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/register';

const Register = () => {
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user])

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd])

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd, matchPwd])

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      return;
    }
    console.log("user %s and password %s", user, pwd)
    try {
      console.log('Trying Axio: ',(process.env.REACT_APP_INVENTORY_IP),':',(process.env.REACT_APP_INVENTORY_PORT))
      const response = await axios.post(REGISTER_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: false
        });
      console.log(response?.data);
      console.log(response?.accessToken);
      //console.log(JSON.stringify(response))
      setSuccess(true);
      //clear state and controlled inputs
      //need value attrib on inputs for this
      setUser('');
      setPwd('');
      setMatchPwd('');
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No existe respuesta desde el Servidor.');
      } else if (err.response?.status === 409) {
        setErrMsg('Usuario Registrado.');
      } else {
        setErrMsg('Registro Fallido.')
      }
      errRef.current.focus();
    }
  }

  return (
    <>
      <div className="container mt-5 ">
        <div className="card bg-transparent border-light">
          <div className="card-body">
            {success ? (
              <section>
                <h1 className="text-success">¡Éxito!</h1>
                <p>
                  <a href="/">Sign In</a>
                </p>
              </section>
            ) : (
              <section>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                 <h1 className="text-light ">Registro</h1>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="username ">
                      <p className="text-light inline-icon ">Usuario:</p>
                      <FontAwesomeIcon icon={faCheck} className={`${validName ? "text-success" : "d-none"} inline-icon`} />
                      <FontAwesomeIcon icon={faTimes} className={`${validName || !user ? "d-none" : "text-danger"}inline-icon`} />
                    </label>
                    <div className="input-box">
                    <input
                      type="text"
                      id="username"
                      className="form-control"
                      ref={userRef}
                      autoComplete="off"
                      onChange={(e) => setUser(e.target.value)}
                      value={user}
                      required
                      aria-invalid={validName ? "false" : "true"}
                      aria-describedby="uidnote2"
                      onFocus={() => setUserFocus(true)}
                      onBlur={() => setUserFocus(false)}
                    />
                    <small id="uidnote2" className={userFocus && user && !validName ? "form-text text-white" : "d-none"}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                      4 a 24 caracteres.<br />
                      Debe comenzar con letras.<br />
                      Letras, números, guion bajo, permitidos.
                    </small>
                    </div>
              </div>

                  <div className="form-group">
                    <label htmlFor="password">
                      <p className="text-light inline-icon"> Contraseña: </p>
                      <FontAwesomeIcon icon={faCheck} className={`${validPwd ? "text-success" : "d-none"} inline-icon`} />
                      <FontAwesomeIcon icon={faTimes} className={`${validPwd || !pwd ? "d-none" : "text-danger"} inline-icon`}/>
                    </label>
                    <div className="input-box">
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      onChange={(e) => setPwd(e.target.value)}
                      value={pwd}
                      required
                      aria-invalid={validPwd ? "false" : "true"}
                      aria-describedby="pwdnote"
                      onFocus={() => setPwdFocus(true)}
                      onBlur={() => setPwdFocus(false)}
                    />
                    <small id="pwdnote" className={pwdFocus && !validPwd ? "form-text text-white" : "d-none"}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                      8 a 24 caracteres.<br />
                      Debe incluir mayúsculas, minúsculas, un número y un carácter especial.<br />
                      Caracteres especiales permitidos: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                    </small>
                    </div>
                  </div>

                  <div className="form-group font-weight-bold">
                    <label htmlFor="confirm_pwd">
                      <p className="text-light  inline-icon"> Confirme la Contraseña:
                        <FontAwesomeIcon icon={faCheck} className={`${validMatch && matchPwd ? "text-success" : "d-none"} inline-icon`}/>
                        <FontAwesomeIcon icon={faTimes} className={`${validMatch || !matchPwd ? "d-none" : "text-danger"} inline-icon`}/>
                      </p>
                    </label>
                    <div className="input-box">
                    <input
                      type="password"
                      id="confirm_pwd"
                      className="form-control"
                      onChange={(e) => setMatchPwd(e.target.value)}
                      value={matchPwd}
                      required
                      aria-invalid={validMatch ? "false" : "true"}
                      aria-describedby="confirmnote"
                      onFocus={() => setMatchFocus(true)}
                      onBlur={() => setMatchFocus(false)}
                    />
                    <small id="confirmnote" className={matchFocus && !validMatch ? "form-text text-muted " : "d-none"}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                      Debe coincidir con lo puesto en la primera celda de contraseña.
                    </small>
                    </div>
                  </div>
                  <br />
                  <button disabled={!validName || !validPwd || !validMatch} className="btn btn-primary">Registrarse</button>
                </form>
                <p className="mt-3 text-white font-weight-bold">
                  ¿Ya registrado?<br />
                  <span className="line">
                    <a href="/">Ingresar</a>
                  </span>
                </p>
               </section>
            )}
          </div> </div>
      </div></>
  );
}

export default Register