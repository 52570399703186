import React from 'react'
import hEader from './header.jsx';
import Nav from '../navBar/navBar.jsx'
import dAsh from './dashboard2.jsx';
import './dashboard.css';


function dashboard() {
  return (
    <div className='DivMayor'>
      <div className=' grid-container'>
        <div className='NavBar'>
        <Nav/>
      </div>
      </div>
    </div>
  )
}

export default dashboard
